function Footer() {
    return (
        <div className={"bg-neutral-900 mt-12 pt-4 pb-12"}>
            <div className={"flex justify-center"}>
                <a href={"/"} className={"p-7 text-gray-500 text-md font-serif"}>Links</a>
                <a href={"/imprint"} className={"p-7 text-gray-500 text-md font-serif"}>Impressum</a>
            </div>
            <p className={"pt-4 text-white text-center text-sm font-serif"}>&copy; {new Date().getFullYear()} Pantomime JOMI</p>
        </div>
    )
}

export default Footer