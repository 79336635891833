import l1 from "../img/repertoire/l1.jpg"
import l2 from "../img/repertoire/l2.jpg"
import l3 from "../img/repertoire/l3.jpg"
import p1 from "../img/repertoire/p1.jpg"
import p2 from "../img/repertoire/p2.jpg"
import p3 from "../img/repertoire/p3.jpg"
import r1 from "../img/repertoire/r1.jpg"
import r2 from "../img/repertoire/r2.jpg"
import r3 from "../img/repertoire/r3.jpg"
import s1 from "../img/repertoire/s1.jpg"
import s2 from "../img/repertoire/s2.jpg"
import s3 from "../img/repertoire/s3.jpg"

function Repertoire() {
    return (
        <div className={"container mx-auto max-w-screen-lg px-8"}>
            <h1 className={"font-thin break-words font-sans text-white text-5xl antialiased pt-8"}>Eine Auswahl
                seines vielseitigen Repertoires</h1>
            <h2 className={"font-thin font-sans sm:text-5xl text-4xl text-accent antialiased text-center pt-24 pb-4"}>Lyrik</h2>
            <div className={"flex flex-wrap justify-center gap-4"}>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={l1} alt={"Romeo und Julia"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Romeo und Julia</p>
                    </div>
                </div>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={l2} alt={"Homage an Marcel Marceau"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Homage an Marcel Marceau</p>
                    </div>
                </div>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={l3} alt={"Vogelscheuche"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Vogelscheuche</p>
                    </div>
                </div>
            </div>
            <h2 className={"font-thin font-sans sm:text-5xl text-4xl text-accent antialiased text-center pt-24 pb-4"}>Parodie</h2>
            <div className={"flex flex-wrap justify-center gap-4"}>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={p1} alt={"Konzertprobe"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Konzertprobe</p>
                    </div>
                </div>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={p2} alt={"Generation Smartphone"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Generation Smartphone</p>
                    </div>
                </div>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={p3} alt={"Junger Vater"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Junger Vater</p>
                    </div>
                </div>
            </div>
            <h2 className={"font-thin font-sans sm:text-5xl text-4xl text-accent antialiased text-center pt-24 pb-4"}>Religiöse Themen</h2>
            <div className={"flex flex-wrap justify-center gap-4"}>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={r1} alt={"Kreuzweg (Passion)"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Kreuzweg (Passion)</p>
                    </div>
                </div>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={r2} alt={"Erschaffung der Erde"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Erschaffung der Erde</p>
                    </div>
                </div>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={r3} alt={"Leben bis zuletzt"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Leben bis zuletzt</p>
                    </div>
                </div>
            </div>
            <h2 className={"font-thin font-sans sm:text-5xl text-4xl text-accent antialiased text-center pt-24 pb-4"}>Sozialkritik</h2>
            <div className={"flex flex-wrap justify-center gap-4"}>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={s1} alt={"Marionette"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Marionette</p>
                    </div>
                </div>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={s2} alt={"Stress (Metronom)"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Stress (Metronom)</p>
                    </div>
                </div>
                <div className={"group relative"}>
                    <img className={"object-contain max-h-[28rem]"} src={s3} alt={"Befreiung aus der Isolation"}/>
                    <div className="absolute grid content-center bottom-0 left-0 right-0 top-0 px-4 py-2 bg-black group-hover:opacity-100 opacity-0 transition duration-300 ease-in-out">
                        <p className="text-center text-md text-white font-serif">Befreiung aus der Isolation</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Repertoire