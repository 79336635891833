import header from "../../img/header.jpg"

function Top() {
    return (
        <section id={"top"}>
            <div className={"container mx-auto max-w-screen-lg px-8"}>
                <div className={"content-center sm:h-[38rem] h-[24rem] grid bg-no-repeat bg-contain bg-center"} style={{backgroundImage: `url(${header})`}}>
                    <div>
                        <h1 className={"font-thin sm:text-7xl text-4xl text-white antialiased sm:pb-8 pb-4 sm:mt-20 top-shadow"}>Pantomime JOMI</h1>
                    </div>
                    <div>
                        <h2 className={"sm:font-thin font-medium sm:font-sans font-serif sm:text-5xl text-2xl text-accent antialiased sm:pb-6 pb-3 top-shadow"}>Die Poesie der Stille</h2>
                    </div>
                    <div>
                        <a href={"mailto:buero@pantomime-jomi.de"} className={"text-white p-1 text-md font-serif font-normal border-b-2 border-accent inline-block"}>Kontaktieren Sie JOMI &#8594;</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Top