import main from "../../img/main1.jpg"

function Artist() {
    return (
        <section id={"artist"}>
            <div className={"container mx-auto max-w-screen-lg px-8"}>
                <div className={"sm:mt-28 mt-16"}>
                    <h1 className={"font-thin font-sans sm:text-5xl text-4xl text-accent antialiased text-center"}>Über den Künstler</h1>
                    <div className={"flex md:flex-row flex-col"}>
                        <img className={"object-contain max-h-[25rem] flex-none"} src={main} alt={"JOMI"}/>
                        <div className={"flex-grow grid content-center md:mt-0 mt-8"}>
                            <p className={"text-white text-md font-serif"}>Pantomime JOMI lässt uns durch seine stille Kunst nachdenklich innehalten und berührt uns mit seiner Körpersprache auf ungewohnte Art.</p>
                            <div>
                                <a href={"/artist"} className={"text-white text-md p-1 mt-5 font-serif font-normal border-b-2 border-accent inline-block"}>Mehr über JOMI &#8594;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Artist