import main from "../../img/main2.jpg";

function Repertoire() {
    return (
        <section id={"repertoire"}>
            <div className={"container mx-auto max-w-screen-lg px-8"}>
                <div className={"sm:mt-28 mt-16"}>
                    <h1 className={"font-thin font-sans sm:text-5xl text-4xl text-accent antialiased text-center"}>Sein Repertoire</h1>
                    <div className={"flex md:flex-row flex-col"}>
                        <div className={"flex-grow grid content-center md:mt-0 mt-8"}>
                            <p className={"text-white text-md font-serif"}>JOMIs Repertoire umfasst Szenen mit sozialkritischen, lyrischen und religiösen Themen, außerdem parodistische Szenen mit liebevollem Spott. Spezielle, nach eigenen Wünschen und vorheriger Absprache zusammenstellbare Themenabende runden sein Programm ab.</p>
                            <div>
                                <a href={"/repertoire"} className={"text-white text-md p-1 mt-5 font-serif font-normal border-b-2 border-accent inline-block"}>Auswahl des Repertoires &#8594;</a>
                            </div>
                        </div>
                        <img className={"object-contain max-h-[25rem] flex-none"} src={main} alt={"JOMI"}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Repertoire