import gallery1 from "../../img/gallery1.jpg"
import gallery2 from "../../img/gallery2.jpg"

function Gallery() {
    return (
        <section id={"gallery"}>
            <div className={"container mx-auto max-w-screen-lg px-8"}>
                <div className={"sm:mt-28 mt-16"}>
                    <h1 className={"font-thin font-sans sm:text-5xl text-4xl text-accent antialiased text-center"}>Galerie</h1>
                    <div className={"flex flex-col justify-center gap-4 md:pt-12 pt-8"}>
                        <img className={"object-contain max-h-[40rem]"} src={gallery1} alt={"Galerie1"}/>
                        <img className={"object-contain max-h-[40rem]"} src={gallery2} alt={"Galerie2"}/>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Gallery