import solo from "../img/solo/solo.jpg";

function Solo() {
    return (
        <div className={"container mx-auto max-w-screen-lg px-8"}>
            <h1 className={"font-thin break-words font-sans text-white text-5xl antialiased pt-8 pb-8"}>Soloveranstaltungen</h1>
            <p className={"text-white text-md font-serif pb-4"}>JOMIs Repertoire umfasst Szenen mit sozialkritischen,
                lyrischen und religiösen Themen, außerdem parodistische Szenen mit liebevollem Spott. Spezielle, nach
                eigenen Wünschen und vorheriger Absprache zusammenstellbare Themenabende runden sein Programm ab.</p>
            <div className={"flex flex-col justify-center gap-4 md:pt-8 pt-4"}>
                <img className={"object-contain max-h-[40rem]"} src={solo} alt={"JOMI"}/>
            </div>
        </div>
    )
}

export default Solo