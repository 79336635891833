function Contact() {
    return (
        <section id={"contact"}>
            <div className={"container mx-auto max-w-screen-lg px-8"}>
                <div className={"sm:mt-28 mt-16"}>
                    <h1 className={"font-thin font-sans sm:text-5xl text-4xl text-accent antialiased text-center"}>Kontakt</h1>
                    <div className={"md:mt-12 mt-8"}>
                        <p className={"mb-4 text-gray-500 text-md font-serif"}>Sie erreichen das Büro von <span className={"text-white text-md font-serif"}>Montag bis Donnerstag</span> zwischen <span className={"text-white text-md font-serif"}>14:30 Uhr und 18:00 Uhr</span> unter der Telefonnummer:</p>
                        <p className={"mb-4 text-gray-500 text-md font-serif"}>Jean Braeunig <span className={"text-white text-md font-serif"}>+49 176/51166991</span></p>
                        <p className={"mb-4 text-gray-500 text-md font-serif"}>Email-Anfragen können Sie senden an <a href={"mailto:buero@pantomime-jomi.de"} className={"text-white text-md font-serif"}>buero@pantomime-jomi.de</a>.</p>
                        <p className={"mb-4 text-white text-md font-serif"}>BÜRO JOMI<br/>Im Eichgarten 15<br/>66822 Lebach<br/>Deutschland</p>
                        <p className={"text-gray-500 text-md font-serif"}>Facebook <a href={"https://facebook.com/pantomime.jomi"} className={"text-white text-md font-serif"}>facebook.com/pantomime.jomi</a></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact