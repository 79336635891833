import artist from '../img/artist/artist.jpg'

function Artist() {
    return (
        <div className={"container mx-auto max-w-screen-lg px-8"}>
            <h1 className={"font-thin break-words font-sans text-white text-5xl antialiased pt-8 pb-8"}>Über den
                Künstler</h1>
            <p className={"text-white text-md font-serif pb-4"}>JOMI (Josef Michael Kreutzer) zählt trotz seiner
                Hörbehinderung weltweit zu den Spitzenkünstlern seines Faches. Er studierte beim weltberühmten
                Pantomimen Marcel Marceau an der École de Mimodrame in Paris. Seine Ausbildung umfasste neben der
                Pantomime u.a. auch Modern Dance, Schauspiel, Akrobatik, Fechten und Maskenspiel. Insofern hat sich JOMI
                nicht nur der klassischen Pantomime verschrieben, sondern ist für die unterschiedlichsten Stilrichtungen
                offen.</p>
            <p className={"text-white text-md font-serif pb-4"}>Zahlreiche Preise und Auszeichnungen begleiteten seinen
                bisherigen künstlerischen Weg. Seine Tourneen führten ihn in viele Länder auf fast allen Kontinenten.
                JOMIs hervorragende Darstellungskunst äußert sich in enormer Wandlungsfähigkeit, hoher Sensibilität und
                großer Präzision.</p>
            <p className={"text-white text-md font-serif pb-4"}>Neben seinen Solovorstellungen gibt JOMI an Theatern,
                Universitäten und Schulen Workshops in Pantomime, Körpersprache und Kommunikation. Dabei sorgt er bei
                Zuschauern und Teilnehmern für Momente des Innehaltens und der Stille in unserer allzu hektischen
                Zeit.</p>
            <div className={"flex flex-col justify-center md:pt-12 pt-8"}>
                <img className={"object-contain max-h-[50rem]"} src={artist} alt={"JOMI"}/>
            </div>
        </div>
    )
}

export default Artist