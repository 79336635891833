import {Component} from "react"

class Events extends Component {
    render() {
        const events = this.props.events
        return (
            <section id={"events"}>
                <div className={"container mx-auto max-w-screen-lg px-8"}>
                    <div className={"sm:mt-28 mt-16"}>
                        <h1 className={"font-thin font-sans sm:text-5xl text-4xl text-accent antialiased text-center"}>Veranstaltungen</h1>
                        <div className={"flex flex-col md:flex-row justify-items-center justify-center gap-8 md:pt-12 pt-8 pb-8"}>
                            <div className={"flex-initial md:rounded-xl md:border md:border-gray-500 md:p-7 md:w-[25rem] md:aspect-square grid justify-center content-start"}>
                                <h2 className={"text-center text-white text-3xl font-sans font-thin antialised"}>Solo Auftritte</h2>
                                <h3 className={"md:pt-8 pt-4 text-center text-white text-2xl font-sans font-thin antialiased"}>Themen</h3>
                                <p className={"pt-2 text-center text-white text-md font-serif"}>nach Absprache</p>
                                <h3 className={"md:pt-8 pt-4 text-center text-white text-2xl font-sans font-thin antialiased"}>Dauer</h3>
                                <p className={"pt-2 text-center text-white text-md font-serif"}>bis zu 90 Minuten</p>
                                <a href={"/solo"} className={"text-white text-md p-1 md:mt-8 mt-4 font-serif font-normal border-b-2 border-accent inline-block"}>Mehr über JOMI als Solokünstler &#8594;</a>
                            </div>
                            <div className={"flex-initial md:rounded-xl md:border md:border-gray-500 md:p-7 md:w-[25rem] md:aspect-square md:mt-0 mt-4 grid justify-center content-start"}>
                                <h2 className={"text-center text-white text-3xl font-sans font-thin antialised"}>Workshops</h2>
                                <h3 className={"md:pt-8 pt-4 text-center text-white text-2xl font-sans font-thin antialiased"}>Themen</h3>
                                <p className={"pt-2 text-center text-white text-md font-serif"}>"Kommunikation und Körpersprache"<br/>"Pantomime"</p>
                                <h3 className={"md:pt-8 pt-4 text-center text-white text-2xl font-sans font-thin antialiased"}>Dauer</h3>
                                <p className={"pt-2 text-center text-white text-md font-serif"}>mehrstündig oder mehrtägig</p>
                                <a href={"/workshops"} className={"text-white text-md p-1 md:mt-8 mt-4 font-serif font-normal border-b-2 border-accent inline-block"}>Mehr über Workshops mit JOMI &#8594;</a>
                            </div>
                        </div>
                        <p className={"text-gray-500 text-md font-serif"}>Sie können die Infomappe unter <span className={"text-white text-md font-serif"}>+49 176/51166991</span> oder <a href={"mailto:buero@pantomime-jomi.de"} className={"inline text-white text-md font-serif"}>per Mail</a> anfordern.</p>
                        {events.length > 0 &&
                            <div>
                                <h2 className={"text-center text-white text-3xl font-sans font-thin antialised pt-16"}>Nächste Veranstaltungen</h2>
                                {events.map((item) => (
                                    <div>
                                        <h3 className={"pt-8 sm:text-center text-left text-accent text-2xl font-sans font-thin antialiased"}>{item.header_text}</h3>
                                        <p className={"pt-4 sm:text-center text-left text-white text-md font-serif"}>{new Date(item.date).toLocaleDateString("de-DE", {year: 'numeric', month: 'long', day: '2-digit'})}, {new Date(item.date).toLocaleTimeString("de-DE", {hour: 'numeric', minute: 'numeric'})} Uhr<br/>{item.location}</p>
                                    </div>
                                ))}
                                <div className={"grid sm:justify-center justify-start"}>
                                    <a href={"/upcoming"} className={"text-white block text-md p-1 md:mt-8 mt-4 font-serif font-normal border-b-2 border-accent inline-block"}>Alle Veranstaltungen &#8594;</a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default Events