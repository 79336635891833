import ws1 from "../img/workshops/ws1.jpg";
import ws2 from "../img/workshops/ws2.jpg";
import ws3 from "../img/workshops/ws3.jpg";

function Workshops() {
    return (
        <div className={"container mx-auto max-w-screen-lg px-8"}>
            <h1 className={"font-thin break-words font-sans text-white text-5xl antialiased pt-8 pb-8"}>Workshops</h1>
            <p className={"text-white text-md font-serif pb-4"}>JOMI ist weltweit nicht nur als Solopantomime bekannt,
                auch seine Workshops in Körpersprache und Kommunikation, gepaart mit pantomimischen Elementen, sind von
                Theatern, Universitäten, Kliniken, Schulen und Firmen sehr gefragt. Ein wesentliches Ziel ist es, die
                eigene Körpersprache zu erkennen und zu verbessern, aber auch zu lernen, wie die Körpersprache anderer
                auf uns wirkt.</p>
            <p className={"text-white text-md font-serif pb-4"}>JOMIs Workshops „Körpersprache und Kommunikation“ und
                „Pantomime“ können mehrstündig oder mehrtägig gebucht und miteinander kombiniert werden. Außerdem kann
                sie JOMI jederzeit auf Anfrage durch individuell gewünschte Themen ergänzen. Auf Anfrage gibt er auch
                Einzelunterricht für Personen, die in ihrem Beruf auf eine ausdrucksvolle Körpersprache angewiesen
                sind.</p>
            <div className={"flex flex-col justify-center gap-4 md:pt-8 pt-4"}>
                <img className={"object-contain max-h-[40rem]"} src={ws1} alt={"Workshop"}/>
                <img className={"object-contain max-h-[40rem]"} src={ws2} alt={"Workshop"}/>
                <img className={"object-contain max-h-[40rem]"} src={ws3} alt={"Workshop"}/>
            </div>
        </div>
    )
}

export default Workshops