import logo from '../img/logo.png'

const navigation = [
    {name: 'Künstler', href: '/#artist'},
    {name: 'Repertoire', href: '/#repertoire'},
    {name: 'Veranstaltungen', href: '/#events'},
    {name: 'Galerie', href: '/#gallery'},
    {name: 'Kontakt', href: '/#contact'},
]

function Navigation() {
    return (
        <div className={"outline outline-gray-500 outline-1"}>
            <div className={"sm:container mx-auto"}>
                <div className={"flex justify-center gap-[4rem]"}>
                    <div className={"self-center md:p-0 p-4"}>
                        <a href={"/"}>
                            <img src={logo} alt={"Home"} className={"h-12"}/>
                        </a>
                    </div>
                    <div className={"md:flex hidden"}>
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href}
                               className={"text-white text-md hover:text-accent p-7 font-serif"}>{item.name}</a>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navigation